#page-room {
    .box-title {
        margin: 24px 0 24px;
        display: flex;
        align-items: center;
        margin-left: 8px;
        justify-content: center;
    }

    .box-list {
        border-radius: 30px;
        box-shadow: inset 0 0 0 2px #FD0178;
        margin: 30px 0px;
        padding: 25px 0px;
        background-color: #fefefe;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;

        img {
            max-width: 500px;
            width: 100%;
        }

        .title-performance-redes {
            margin: 10px;

            h1 {
                color: #000;
                text-align: center;
            }
        }

        .item-performance-redes {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            input {
                font-size: 16px;
                width: 280px;
                height: 30px;
                border-radius: 6px;
                cursor: pointer;
                padding: 2px 22px 2px 2px;
            }

            .number-redes {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px;
                margin: 0 2px;
                border-radius: 10px;

                a{
                    color: #FD0178;
                    text-decoration: none;
                    font-weight: bold;
                    transition: color 0.3s;
                    cursor: pointer;
                    margin-top: 10px;
                }
                a:hover {
                    color: #7a7a7a;
                }

                span {
                    margin-top: 5px;
                    background-color: #FD0178;
                    width: 85px;
                    height: 85px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fefefe;
                    font-size: 24px;
                }
                
                label{
                    color: #000
                }
            }

            .icon-facebook {
                fill: #385898;
                cursor: pointer;
            }

            .icon-whatsapp {
                fill: rgb(0, 211, 148);
                cursor: pointer;
            }

            .icon-linkedin {
                fill: rgb(2, 116, 179);
                cursor: pointer;
            }

            .redes-copy {
                border-radius: 6px;
                height: 30px;
                width: 30px;
                margin-left: -30px;
                //background-color: rgb(239, 108, 12);
                background-color: var(--color-primary);
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                cursor: pointer;

                svg {
                    fill: #FD0178;
                    margin: 5px;
                }

            }
        }
    }
}