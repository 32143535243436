#page-room {

    .content-photo-new {
        max-width: 800px;
        margin: 0 auto;

        .title {
            margin: 32px 10px 24px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            justify-content: space-between;
            height: 50px;

            h1 {
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #fff;
            }
        }

        .description {
            margin: 0px 10px 24px;

            label {
                color: #fff;
            }


        }

        .form {
            margin: 32px 8px 24px 8px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            flex-direction: column;

            form {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
                max-width: 350px;

                label {
                    margin: 16px;
                    color: #fff;
                }

                .checkbox {
                    background: #fff;
                    border: 1px solid #a8a8b3;
                    height: 20px;
                    width: 20px;
                }

                input {
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }

                select {
                    width: 100%;
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }

                button {
                    margin-top: 16px;
                }

                button,
                input {
                    width: 100%;
                }



            }
        }
    }
}