#page-compra {
    header {
        padding: 2px;
        border-bottom: 1px solid #3d3d3d;

        .content-header {
            max-width: 1128px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 10px;

            img {
                height: 56px;
            }
        }
    }

    
    main {
        max-width: 800px;
        margin: 0 auto;

        .room-title{
            margin: 32px 0 24px;
            display: flex;
            align-items: flex-start;
            margin-left: 8px;
            flex-direction: column;

            h1{
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #fff;
            }

            h3{
                font-family: 'Pappins', sans-serif;
                font-size: 18px;
                color: #7a7a7a
            }

            span{
                margin-left: 16px;
                background: #e559f9;
                border-radius: 9999px;
                padding: 8px 16px;
                color: #FFF;
                font-weight: 500;
                font-size: 14px;
            }

        }

        .compra-list{
            margin: 32px 0 24px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            flex-direction: column;
            form{
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
                max-width: 350px;

                input{
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }
    
                button{
                    margin-top: 16px;
                }
    
                button, input {
                    width: 100%;
                }
    
            }
        }

        form{
            textarea{
                width: 100%;
                border: 0;
                padding: 16px;
                border-radius: 8px;
                background: #fefefe;
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
                resize: vertical;
                min-height: 130px;
            }
        }
        .form-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .user-info{
                display: flex;
                align-items: center;

                img{
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }

                span{
                    margin-left: 8px;
                    color: #29292e;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            .login-info{
                display: flex;
                align-items: center;

                span{
                    font-size: 14px;
                    color: #737388;
                    font-weight: 500;

                    button{
                        background: transparent;
                        border: 0;
                        color: #835AFD;
                        text-decoration: underline;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
        }

        .question-list{
            margin-top: 32px;

            .list-flex{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: center;

            }
        }

    }
}