#page-room {
    header {
        padding: 2px;
        border-bottom: 1px solid #3d3d3d;

        .content-header {
            max-width: 1128px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row;

            .actions {
                display: flex;

                @media(max-width: 620px) {
                    flex-direction: column;
                }
            }

            .navbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 1128px;
                width: 100%;
                margin: 0 auto;
                padding: 10px;
            }

            .logo {
                height: 56px;
            }

            .menu {
                list-style: none;
                display: flex;
                margin: 0;
                padding: 0;
            }

            .menu li {
                margin-right: 20px;
            }

            

            .menu a, .menu span {
                color: #FD0178;
                text-decoration: none;
                font-weight: bold;
                transition: color 0.3s;
                cursor: pointer;
                
            }

            .menu .item-active {
                color: #dedede;
            }

            .menu a:hover, .menu span:hover {
                color: #dedede;
            }

            .menu-toggle {
                display: none;
                flex-direction: column;
                cursor: pointer;
                color: #FD0178;
            }

            .menu-toggle.active .bar:nth-child(1) {
                transform: translateY(6px) rotate(45deg);
            }

            .menu-toggle.active .bar:nth-child(2) {
                opacity: 0;
            }

            .menu-toggle.active .bar:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg);
            }

            @media screen and (max-width: 799px) {
                .menu {
                    display: none;
                    flex-direction: column;
                    background-color: #111;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    text-align: center;
                    z-index: 1;
                }

                .menu.active {
                    display: flex;
                    li {
                        margin: 20px;
                    }
                }

                .menu-toggle {
                    display: flex;
                    flex-direction: column;
                    height: 24px;

                    svg{
                        fill: #FD0178;
                    }
                }
            }

            >img {
                max-height: 45px;
                margin: 10px;
                cursor: pointer;
            }

            >button {
                margin: 10px;
            }

            >div {
                display: flex;

                button {
                    height: 40px;
                    margin-left: 16px;
                }
            }
        }
    }
}