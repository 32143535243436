#page-room {

    main, .content-home  {
        max-width: 800px;
        margin: 0 auto;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 800px;
            width: 100%;
            background-color: #111;

            .searching {
                margin: 5px;

                h1 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 28px;
                    color: #fff;
                }
            }

            .filter {
                display: flex;
                padding: 10px;
                max-width: 370px;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;

                @media only screen and (min-width: 760px) {
                    padding: 15px;
                    flex-direction: row-reverse;
                    max-width: 100%;
                }

                input,
                select {
                    height: 50px;
                    border-radius: 8px;
                    padding: 0 16px;
                    margin: 10px 0;
                    background: #fff;
                    border: 1px solid #a8a8b3;
                }
            }

        }

        .lista-videos {
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;

            @media only screen and (min-width: 760px) {
                justify-content: space-between;

            }

            .no-data {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 24px;

                p {
                    font-family: 'Pappins', sans-serif;
                    font-size: 22px;
                    color: #fff;
                }
            }

        }

        .lista-videos article {
            width: 100%;
            max-width: 380px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;

        }

        .lista-videos article a {
            text-decoration: none;
            color: #FFF;
            background-color: brown;
            font-size: 25px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .video {
            cursor: pointer;
        }

        .thumb {
            width: 100%;
            height: 200px;
            max-width: 350px;
            background-size: cover;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-end;
            flex-direction: row;
            box-shadow: inset 0px -40px 20px 2px rgba(0, 0, 0, 0.9);
            position: relative;

            .tag {
                position: absolute;
                top: 8px;
                right: 8px;
                display: flex;
                -webkit-box-pack: end;
                justify-content: flex-end;
                -webkit-box-align: center;
                align-items: center;

                .freeTag {
                    height: 20px;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    background: linear-gradient(90deg, rgb(176, 121, 8) 6.94%, rgb(208, 171, 60) 38.36%, rgb(234, 212, 103) 67.83%, rgb(244, 228, 120) 82.8%);
                    text-transform: uppercase;
                    border-radius: 16px;
                    font-weight: 800;
                    font-size: 0.8rem;
                    padding: 4px 16px;
                    color: black;
                    font-family: 'Pappins', sans-serif;
                }
            }

            .locker {
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                background:rgba(10, 10, 10, 0.9);

                .freeLocker {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 120px;
                        width: 120px;
                    }

                    h3 {
                        color: aliceblue;
                    }
                }

            }
        }

        .thumb-h6 {
            color: aliceblue;
            margin: 5px;
            font-size: 20px;
            font-family: 'Pappins', sans-serif;
        }

    }
}