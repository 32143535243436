.carousel-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 32px;
    width: 100%;

    h2{
        margin: 10px;
    }
}

.carousel .slide {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel .slider-wrapper {
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    @media only screen and (min-width: 560px) {
        width: 92%;
    }
}

.carousel .legend {
    text-align: center;
    font-size: 20px;
    color: #fff;
}

.carousel .thumbs-wrapper {
    display: none;
}

.padding-25 {
    padding: 25px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.0);
}

.carousel .slide {
    min-width: 100%!important;
    @media only screen and (min-width: 560px) {
        min-width: 50%!important;
    }
}