#page-room {
    .content-lotto {
        max-width: 800px;
        margin: 0 auto;

        .title-lotto {
            margin: 32px 10px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media only screen and (min-width: 400px) {
                justify-content: space-between;
                flex-direction: row;
            }

            h1 {
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #fff;
                margin: 10px 0px;
            }

            input,
            select {
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                background: #fff;
                border: 1px solid #a8a8b3;
            }
        }

        .container-lotto {
            margin-top: 32px;

            .lista-lotto {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: center;
                margin: 10px;

                .no-data {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 24px;

                    p {
                        font-family: 'Pappins', sans-serif;
                        font-size: 22px;
                        color: #fff;
                        margin-bottom: 16px;
                        text-align: justify;
                    }
                }

                .lotto-table {
                    background: #1f1f1f;
                    border-radius: 8px;
                    padding: 15px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;

                    .lotto-item {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-content: center;



                        .flex-col {
                            display: flex;
                            flex-direction: column;
                            margin: 16px;

                            .label {
                                font-weight: 700;
                                color: #c8c8c8;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }

                            .label-valor {
                                font-weight: 400;
                                color: #c8c8c8;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }

                            .label-valor-destaque{
                                font-weight: 700;
                                color: #FD0178;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }
                        }




                        p {
                            color: #FFF;
                        }

                        .item-ranking {
                            font-weight: 500;

                            @media only screen and (max-width: 400px) {
                                font-size: 13px;
                            }

                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }

                        .head-ranking {
                            font-weight: 700;

                            @media only screen and (max-width: 400px) {
                                font-size: 13px;
                            }
                        }
                    }

                    .lotto-botton {
                        display: flex;
                        margin-top: 10px;
                        flex-direction: row-reverse;

                        .flex-botton {
                            display: flex;

                            .button {
                                margin-left: 10px;
                            }
                        }

                        .flex-col {
                            display: flex;
                            flex-direction: column;
                            margin: 16px;

                            .label {
                                font-weight: 700;
                                color: #c8c8c8;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }

                            .label-valor {
                                font-weight: 400;
                                color: #c8c8c8;

                                @media only screen and (max-width: 400px) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

.modal-lotto {
    margin-top: 32px;

    .lotto-table-top {
        background: #1f1f1f;
        border-radius: 8px;
        padding: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .lotto-item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: center;



            .flex-col {
                display: flex;
                flex-direction: column;
                margin: 6px;

                .label {
                    font-weight: 700;
                    color: #c8c8c8;

                    @media only screen and (max-width: 400px) {
                        font-size: 13px;
                    }
                }

                .label-valor {
                    font-weight: 400;
                    color: #c8c8c8;

                    @media only screen and (max-width: 400px) {
                        font-size: 13px;
                    }
                }

                .label-valor-destaque{
                    font-weight: 700;
                    color: #FD0178;

                    @media only screen and (max-width: 400px) {
                        font-size: 13px;
                    }
                }
            }




            p {
                color: #FFF;
            }

            .item-ranking {
                font-weight: 500;

                @media only screen and (max-width: 400px) {
                    font-size: 13px;
                }

                img {
                    width: 18px;
                    height: 18px;
                }
            }

            .head-ranking {
                font-weight: 700;

                @media only screen and (max-width: 400px) {
                    font-size: 13px;
                }
            }
        }

        .lotto-botton {
            display: flex;
            margin-top: 10px;
            flex-direction: row-reverse;

            .flex-botton {
                display: flex;

                .button {
                    margin-left: 10px;
                }
            }

            .flex-col {
                display: flex;
                flex-direction: column;
                margin: 16px;

                .label {
                    font-weight: 700;
                    color: #c8c8c8;

                    @media only screen and (max-width: 400px) {
                        font-size: 13px;
                    }
                }

                .label-valor {
                    font-weight: 400;
                    color: #c8c8c8;

                    @media only screen and (max-width: 400px) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}