#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .video-info {
            margin: 32px 8px 24px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .video-title{
                margin: 5px;
                h1 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 28px;
                    color: #fff;
                }
            }

            .video-player{
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 10px 0;
            }

            .video-desc{
                margin: 5px;
                h3 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 16px;
                    color: #7a7a7a;
                    font-weight: 500;
                }
            }
        }
    }
}