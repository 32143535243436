#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .container-favorites {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 800px;
            width: 100%;
            background-color: #111;

            .title-favorites {
                margin: 32px 10px 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                h1 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 28px;
                    color: #fff;
                }
            }

        }

        .lista-videos {
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media only screen and (min-width: 800px) {
                justify-content: space-between;

            }

        }

        .lista-videos article {
            width: 100%;
            max-width: 380px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;

        }

        .lista-videos article a {
            text-decoration: none;
            color: #FFF;
            background-color: brown;
            font-size: 25px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .video {
            cursor: pointer;
        }

        .thumb {
            width: 100%;
            height: 200px;
            max-width: 350px;
            background-size: cover;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-end;
            flex-direction: row;
            box-shadow: inset 0px -40px 20px 2px rgba(0, 0, 0, 0.9);
        }

        .thumb-h6 {
            color: aliceblue;
            margin: 5px;
            font-size: 20px;
        }

    }
}