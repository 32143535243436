#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .title-contos {
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media only screen and (min-width: 400px) {
                justify-content: space-between;
                flex-direction: row;
                margin: 32px 10px 24px;
            }

            h1 {
                font-family: 'Pappins', sans-serif;
                font-size: 28px;
                color: #fff;
                margin: 10px 0px;
            }

            input, select {
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                background: #fff;
                border: 1px solid #a8a8b3;
            }
        }

        .container-contos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 800px;
            width: 100%;
            background-color: #111;

            

        }

        .lista-contos {
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media only screen and (min-width: 500px) {
                justify-content: space-evenly;

            }

        }

        .lista-contos article {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            margin-left: 10px;
            margin-right: 10px;

        }

        .lista-contos article a {
            text-decoration: none;
            color: #FFF;
            background-color: brown;
            font-size: 25px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .conto {
            cursor: pointer;
        }

        .thumb-conto {
            width: 90dvw;
            max-width: 100%;
            max-height: 200px;
            height: 30dvw;
            background-size: cover;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-end;
            flex-direction: row;
            box-shadow: inset 0px -40px 20px 2px rgba(0, 0, 0, 0.9);
        }

        .thumb-h6 {
            color: aliceblue;
            margin: 5px;
            font-size: 20px;
        }

    }
}