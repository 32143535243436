#page-room {
    main {

        .container {
            width: 100%;

            .section {
                margin: 20px 0px;
                width: 100%;
                display: flex;
                flex-direction: row;
                @media screen and (max-width: 700px) {
                    flex-direction: column;
                }

                .col-50 {
                    width: 50%;
                    min-height: 320px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    @media screen and (max-width: 700px) {
                        width: 100%;
                        margin: 20px 0px;
                    }

                    h2{
                        font-size: 1.5rem;
                        text-align: center;
                    }

                    h4{
                        font-size: 13px;
                        text-align: center;
                        color: #a8a8b3;
                        max-width: 300px;
                    }

                    img{
                        border-radius: 100px 100px 100px 100px;
                        height: 120px;
                    }
                }
            }
        }

        .footer {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            margin-top: 50px;
            margin-bottom: 10px;

            ul {
                list-style: none;
                display: flex;
                margin: 0;
                padding: 0;

                @media screen and (max-width: 699px) {
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                }

                li {
                    margin-right: 20px;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-weight: 600;
                        transition: color 0.3s;
                        cursor: pointer;
                        font-size: 15px;
                    }

                    a:hover {
                        color: #a8a8b3;
                    }
                }
            }
        }
    }
}