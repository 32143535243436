.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #29292e;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    background-color: #111;
    max-width: 800px;
    max-height: 80dvh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-close {
        display: flex;
        flex-direction: row-reverse;
    }

    .modal-detail {
        margin: 32px 8px 24px 8px;
        display: flex;
        align-items: center;
        margin-left: 8px;
        flex-direction: column;

        h3 {
            margin: 16px;
            color: #fff;
        }

        .pix-detail {
            display: flex;
            flex-direction: column;

            h3 {
                margin: 16px;
                color: #fff;
            }

            label {
                margin: 16px;
                color: #fff;
            }

            input {
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                margin: 10px 0;
                background: #fff;
                border: 1px solid #a8a8b3;
            }
            

            .separator {
                font-size: 14px;
                color: #a8a8b3;
                margin: 20px 0;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    flex: 1;
                    height: 1px;
                    background: #a8a8b3;
                    margin-right: 16px;
                }

                &::after {
                    content: '';
                    flex: 1;
                    height: 1px;
                    background: #a8a8b3;
                    margin-left: 16px;
                }
            }

        }
    }
}


.overlay-modal {
    position: fixed;
    inset: 0px;
    background-color: rgba(17, 17, 17, 0.75);
}