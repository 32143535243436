#page-room {

    main {
        max-width: 800px;
        margin: 0 auto;

        .post-info {
            margin: 32px 10px 24px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            article {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
            }

            .thumb-conto-detail {
                width: 100%;
                height: 200px;
                background-size: cover;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-end;
                flex-direction: row;
            }

            .post-title {
                margin: 10px 0px;

                h1 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 28px;
                    color: #fff;
                }
            }

            .post-player {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 10px 0;
            }

            .post-desc {
                margin: 10px 0px;

                h3 {
                    font-family: 'Pappins', sans-serif;
                    font-size: 16px;
                    color: #7a7a7a;
                    font-weight: 500;
                    text-align: justify;
                }
            }
        }
    }
}

